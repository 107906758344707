.CertifiedDiamonds_page .container {
  max-width: 1600px;
  width: 100%;
}
.custom-design-banner {
  position: relative;
}
.custom-design-banner h3 {
  position: absolute;
  bottom: 50px;
  width: 100%;
  z-index: 2;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}
.custom-design-1 {
  background: #b9994b;
  color: #fff;
  text-align: center;
  padding: 80px 0;
}
.custom-design-1 h3 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}
.custom-design-2 h4 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 40px;
}
.custom-design-1 p,
.custom-design-2 p {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
.custom-design-1 .container {
  max-width: 820px;
  width: 100%;
}
.custom-design-2 .container {
  padding: 80px 0;
}
.custom-design-2 .container .row {
  padding-bottom: 60px;
}
.text-black {
  color: #000;
}
.CertifiedDiamonds-banner {
  position: relative;
}
.CertifiedDiamonds-banner .CertifiedDiamonds-banner-txt {
  position: absolute;
  bottom: 30px;
  color: #fff;
  width: 100%;
}
.CertifiedDiamonds-2 {
  padding: 50px 0 0;
}
.CertifiedDiamonds-2 .container {
  text-align: center;
}
.CertifiedDiamonds-banner .CertifiedDiamonds-banner-txt h3 {
  font-size: 40px;
  font-weight: 700;
}
.CertifiedDiamonds-banner .CertifiedDiamonds-banner-txt p {
  font-size: 16px;
  font-weight: 400;
}
.CertifiedDiamonds-2 .CertifiedDiamonds-box,
.CertifiedDiamonds-6 {
  padding: 50px 0;
  background: #f2f2f2;
}
.CertifiedDiamonds-2 .CertifiedDiamonds-box .container {
  max-width: 680px;
  width: 100%;
  padding: 0;
}
.CertifiedDiamonds-3 {
  padding: 80px 0;
}
.CertifiedDiamonds-2 .container h3,
.CertifiedDiamonds-3 h4,
.CertifiedDiamonds-3 h3,
.CertifiedDiamonds-4 h3,
.CertifiedDiamonds-5 h3,
.CertifiedDiamonds-6 h3,
.CertifiedDiamonds-7 h3,
.CertifiedDiamonds-8 h3,
.CertifiedDiamonds-9 h3 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 38px;
}

.CertifiedDiamonds-2 .container p,
.CertifiedDiamonds-3 p,
.CertifiedDiamonds-3 li,
.CertifiedDiamonds-3 p,
.CertifiedDiamonds-4 p,
.CertifiedDiamonds-4 li,
.CertifiedDiamonds-5 p,
.CertifiedDiamonds-5 li,
.CertifiedDiamonds-6 p,
.CertifiedDiamonds-7 p,
.CertifiedDiamonds-8 p,
.CertifiedDiamonds-9 p,
.CertifiedDiamonds-7 li {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
.CertifiedDiamonds-7 h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.CertifiedDiamonds-5,
.CertifiedDiamonds-6,
.CertifiedDiamonds-9 {
  padding: 50px;
  text-align: center;
}
.CertifiedDiamonds-3 li {
  margin-bottom: 10px;
}
.CertifiedDiamonds-4 {
  background: #f1f1f1;
  padding: 60px 0;
}

.CertifiedDiamonds-7,
.CertifiedDiamonds-8 {
  padding: 60px 0;
}

@media (max-width: 768px) {
  .Custom_Designs_page .custom-design-2 {
    padding: 0px 20px;
  }
  .custom-design-banner h3 {
    position: static;
    font-size: 20px;
    color: #000;
    padding: 10px;
  }
  .custom-design-1 h3,
  .CertifiedDiamonds-2 .container h3,
  .CertifiedDiamonds-3 h4,
  .CertifiedDiamonds-3 h3,
  .CertifiedDiamonds-4 h3,
  .CertifiedDiamonds-5 h3,
  .CertifiedDiamonds-6 h3,
  .CertifiedDiamonds-7 h3,
  .CertifiedDiamonds-8 h3,
  .CertifiedDiamonds-9 h3 {
    font-size: 18px;
    line-height: 30px;
  }
  .CertifiedDiamonds-banner .CertifiedDiamonds-banner-txt h3 {
    font-size: 15px;
  }
  .custom-design-1 p,
  .CertifiedDiamonds-banner .CertifiedDiamonds-banner-txt p,
  .CertifiedDiamonds-2 .container p,
  .CertifiedDiamonds-3 p,
  .CertifiedDiamonds-3 li,
  .CertifiedDiamonds-3 p,
  .CertifiedDiamonds-4 p,
  .CertifiedDiamonds-4 li,
  .CertifiedDiamonds-5 p,
  .CertifiedDiamonds-5 li,
  .CertifiedDiamonds-6 p,
  .CertifiedDiamonds-7 p,
  .CertifiedDiamonds-8 p,
  .CertifiedDiamonds-9 p,
  .CertifiedDiamonds-7 li {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  .custom-design-2 h4 {
    font-size: 18px;
    margin-bottom: 13px;
    line-height: 26px;
    margin-top: 20px;
  }
  .custom-design-2 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  .custom-design-2 .container .row {
    padding-bottom: 0;
  }
  .custom-design-1 {
    padding: 24px 0;
  }

  .CertifiedDiamonds-banner .CertifiedDiamonds-banner-txt {
    bottom: 10px;
  }
  .CertifiedDiamonds-5,
  .CertifiedDiamonds-6,
  .CertifiedDiamonds-9 {
    padding: 20px;
    text-align: center;
  }
  .CertifiedDiamonds-3 {
    padding: 35px 0;
  }
  .CertifiedDiamonds_page img {
    margin-bottom: 20px;
  }
  .CertifiedDiamonds-2 {
    padding: 0px 0 0;
  }
  .CertifiedDiamonds-7,
  .CertifiedDiamonds-8 {
    padding: 25px 0;
  }
  .CertifiedDiamonds-2 .CertifiedDiamonds-box,
  .CertifiedDiamonds-6 {
    padding: 26px 0 1px;
  }
}

/* Jewelry Insurance */
.JewelryInsurance_page .container {
  max-width: 1600px;
  width: 100%;
}

.JewelryInsurance-banner {
  position: relative;
}
.JewelryInsurance-banner .JewelryInsurance-banner-txt {
  position: absolute;
  bottom: 20px;
  color: #fff;
  width: 100%;
}
.JewelryInsurance-banner .JewelryInsurance-banner-txt h3,
.JewelryInsurance-1 h3 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}
.JewelryInsurance-1 h4,
.JewelryInsurance-2 h4,
.JewelryInsurance-4 h4 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.JewelryInsurance-banner .JewelryInsurance-banner-txt p,
.JewelryInsurance-1 p,
.JewelryInsurance-1 li,
.JewelryInsurance-2 p,
.JewelryInsurance-4 p,
.JewelryInsurance-4 li {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
.JewelryInsurance-1 ul {
  padding-left: 15px;
  margin-bottom: 20px;
}
.JewelryInsurance-1 ul li {
  list-style: circle !important;
}
.JewelryInsurance-1 {
  padding: 60px 0;
}

.JewelryInsurance-2 {
  background: #f2f2f2;
  padding: 60px 0;
}
.JewelryInsurance-2 {
  text-align: center;
}
.JewelryInsurance-2 p,
.JewelryInsurance-4 p {
  margin: 0;
  text-align: center;
}
.JewelryInsurance-4 .container {
  background: #f2f2f2;
  text-align: center;
  padding: 60px 0;
}
.JewelryInsurance-4 ul {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  margin: auto;
}
.JewelryInsurance_page .JewelryInsurance-2 .container {
  max-width: 700px;
  width: 100%;
}
.JewelryInsurance-4 ul li {
  width: 50%;
  text-align: left;
  margin-bottom: 20px;
}
.JewelryInsurance-4 ul li span {
  width: 24px;
  height: 24px;
  background: #000;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  margin-right: 10px;
}

.CertifiedDiamonds-6 .container {
  max-width: 800px;
  width: 100%;
}

@media (max-width: 768px) {
  .JewelryInsurance-banner img {
    height: 300px;
    object-fit: cover;
    object-position: left;
  }
  .JewelryInsurance-banner-txt {
    padding: 0 20px;
  }
  .JewelryInsurance-banner .JewelryInsurance-banner-txt h3,
  .JewelryInsurance-1 h3 {
    font-size: 18px;
  }
  .JewelryInsurance-1 h4,
  .JewelryInsurance-2 h4,
  .JewelryInsurance-4 h4 {
    font-size: 16px;
  }
  .JewelryInsurance-banner .JewelryInsurance-banner-txt p,
  .JewelryInsurance-1 p,
  .JewelryInsurance-1 li,
  .JewelryInsurance-2 p,
  .JewelryInsurance-4 p,
  .JewelryInsurance-4 li {
    font-size: 12px;
    line-height: 20px;
  }
  .JewelryInsurance-banner .JewelryInsurance-banner-txt {
    bottom: 0;
  }
  .JewelryInsurance-1,
  .JewelryInsurance-2,
  .JewelryInsurance-4 .container {
    padding: 15px 0;
  }
  .JewelryInsurance-4 ul li {
    width: 100%;
    padding: 0 20px;
  }
  .JewelryInsurance-1 img {
    margin-bottom: 10px !important;
  }
}

/* Proposal Event */

.EventsProposal-banner {
  position: relative;
}
.EventsProposal-banner-text {
  position: absolute;
  width: 100%;
  bottom: 40px;
  text-align: center;
}
.EventsProposal-banner-text h3 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.EventsProposal-1 {
  background: #b9994b;
  color: #fff;
  padding: 60px 0;
}
.EventsProposal-1 h3, .EventsProposal-4 h3, .EventsProposal-5 h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.EventsProposal-1 p, .EventsProposal-1 li, .EventsProposal-4 p, .EventsProposal-5 p, .EventsProposal-5 li {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
.EventsProposal-1 li, .EventsProposal-5 li {
  list-style: circle !important;
  margin-bottom: 10px;
}
.EventsProposal-1 ul, .EventsProposal-5 ul {
  padding-left: 20px;
}
.EventsProposal-4 {
  padding: 60px 0;
}
.EventsProposal-4 .container {
  background: #F7F7F7;
}
.EventsProposal-4 .EventsProposal-4__content {
  max-width: 815px;
  width: 100%;
  padding: 60px 0;
  text-align: center;
  margin: auto;
}
.EventsProposal-5 h4, .EventsProposal-4 h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.EventsProposal-5 {
  padding: 60px 0;
}

@media(max-width: 768px) {
  .EventsProposal-banner-text h3 {
    font-size: 18px;
}
.EventsProposal-banner-text{
  bottom: 0;
}
.EventsProposal-1 h3 {
  text-align: left !important;
}
.EventsProposal-1 h3, .EventsProposal-4 h3, .EventsProposal-5 h3 {
  font-size: 18px;
  line-height: 28px;
}
.EventsProposal-1 p, .EventsProposal-1 li, .EventsProposal-4 p, .EventsProposal-5 p, .EventsProposal-5 li {
  font-size: 14px;
  line-height: 25px;
}
.EventsProposal-4 .EventsProposal-4__content {
  padding: 35px 0;
}
.EventsProposal-4 {
  padding: 0;
}
.EventsProposal-5 {
  padding: 20px 0;
}
.EventsProposal-5 img {
  margin-bottom: 20px;
}
.EventsProposal-5 h4, .EventsProposal-4 h4 {
  font-size: 15px;
  margin-bottom: 10px;
}
}